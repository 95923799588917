import React from "react";
import { Route } from "react-router-dom";
import Home from "../pages/home/Home";
import About from "../pages/about/About";
import FAQs from "../pages/faqs/FAQs";
import Contact from "../pages/contact/Contact";
import Download from "../pages/download/Download";

const routes = [
  {
    path: "/",
    component: Home,
    key: "home",
  },
  {
    path: "/home",
    component: Home,
    key: "home2",
  },
  {
    path: "/about",
    component: About,
    key: "about",
  },
  {
    path: "/FAQs",
    component: FAQs,
    key: "faqs",
  },
  {
    path: "/contact",
    component: Contact,
    key: "Contact",
  },
  {
    path: "/download",
    component: Download,
    key: "download",
  },
];

export default function RouteList() {
  return routes?.map((item) => {
    if (item.path.split("/").length === 2) {
      return (
        <Route
          exact
          path={item.path}
          component={item.component}
          key={item.key}
        />
      );
    }
    return (
      <Route
        path={item.path}
        component={item.component}
        key={item.key}
        exact={item.exact}
      />
    );
  });
}
