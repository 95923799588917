import React from "react";
import "./footer.scss";
import { Col, Row } from "antd";
import Logo from "../../images/logo.png";
import DividerFooter from "../../images/divider-footer.png";
import Bank from "../../images/bank.png";
import Facebook from "../../images/facebook.png";
import CopyRight from "../../images/copyright.png";
import LineDivider from "../../images/line-divider.png";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer() {
  const history = useHistory();
  const { t } = useTranslation();
  const arrQuickLink = [
    { text: "Home" },
    { text: "About" },
    { text: "FAQs" },
    { text: "Contact" },
    { text: "Download" },
  ];

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  function handleRedirect(values) {
    history.push(`/${values}`);
    topFunction();
  }

  return (
    <div className="footer-container">
      <Row justify="center">
        <Col xl={16} sm={23} xs={23}>
          <Row className="footer-first-container">
            <Col sm={8} xs={24}>
              <Row className="logo-container">
                <Col sm={6} xs={8}>
                  <img src={Logo} alt="logo" />
                </Col>
                <Col sm={12}></Col>
              </Row>

              <Row className="">
                <Col xs={8}>
                  <p>{t("operatingHour")}</p>
                </Col>
                <Col xs={12}>
                  <div>
                    <p>Monday - Sunday</p>
                    <p>10:00AM - 8:00PM</p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={8}>
                  <p>{t("ContactNumber")}</p>
                </Col>
                <Col xs={12}>
                  <p>+6011 2345 7890</p>
                </Col>
              </Row>

              <Row>
                <Col xs={8}>
                  <p>{t("Address")}:</p>
                </Col>
                <Col xs={12}>
                  <p>
                    21 Jln Pjs 11/7 Taman Bandar Sunway Petaling Jaya , 46150 ,
                    Selangor , Malaysia
                  </p>
                </Col>
              </Row>
            </Col>

            <Col sm={8} xs={6}>
              <Row>
                <Col>
                  <span>{t("QuickLink")}</span>
                </Col>
              </Row>

              <Row>
                <Col>
                  <img src={DividerFooter} alt="dividerfooter" />
                </Col>
              </Row>

              {arrQuickLink?.map((items) => {
                return (
                  <Row>
                    <Col>
                      <p onClick={() => handleRedirect(items.text)}>
                        {items.text}
                      </p>
                    </Col>
                  </Row>
                );
              })}
            </Col>

            <Col sm={8} xs={12}>
              <Row>
                <Col>
                  <span>{t("CashPickupOptions")}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <img src={DividerFooter} alt="dividerfooter" />
                </Col>
              </Row>

              <Row style={{ marginTop: "5px" }}>
                <Col>
                  <p> {t("Atselectedbranchesof")}:</p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <img src={Bank} alt="bank" style={{ width: "100%" }} />
                </Col>
              </Row>

              <Row style={{ marginTop: "15px" }}>
                <Col>
                  <p style={{ marginTop: "0" }}>
                    {} {t("SocialMedia")}:
                  </p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <img
                    style={{ cursor: "pointer" }}
                    src={Facebook}
                    alt="facebook"
                    onClick={() => {
                      window.open("https://facebook.com/AntsLoan11");
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <img src={LineDivider} alt="line" style={{ width: "100%" }} />
            </Col>
          </Row>

          <Row className="copy-right-container">
            <Col sm={6} xs={6}>
              <div>2023 - 2024 Antsloan</div>
            </Col>

            <Col sm={9} xs={24}>
              <div>All Rights Reserved. Powered By Antsloan.</div>
            </Col>
            <Col sm={9} xs={24}>
              <div>
                Data Deletion Instruction | Privacy Policy | Terms & Conditions.
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Footer;
