import React from "react";
import "./download.scss";
import { Button, Col, Row } from "antd";
import DownLoadLogo from "../../images/download-logo.png";
import Android from "../../images/android.png";
import { useTranslation } from "react-i18next";

function Download() {
  const { t } = useTranslation();

  return (
    <div className="download-container">
      <div className="download-title-container">
        <Row justify="center">
          <Col>
            <p className="title">{t("Download")}</p>
          </Col>
        </Row>
      </div>

      <div className="download-desc-container">
        <Row justify="center">
          <Col>
            <img src={DownLoadLogo} alt="DownLoadLogo" />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <div className="logo-name">Antsloan</div>
          </Col>
        </Row>

        <Row justify="center">
          <Col>
            <div className="logo-desc">{t("versionGetStarted")}</div>
          </Col>
        </Row>

        <Row justify="center">
          <Col sm={6} xs={12}>
            <div className="button-download-android">
              <Button htmlType="submit">
                <img src={Android} alt="android" />
                {t("Download")}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Download;
